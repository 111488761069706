import { extendTheme } from "@chakra-ui/react";

const theme = {
  fonts: {
    heading: "Halant, serif",
    body: "Nunito Sans, sans-serif",
  },
  styles: {
    global: {
      "html, body, #___gatsby, #gatsby-focus-wrapper": {
        height: "100%",
      },
    },
  },
  colors: {
    brand: {
      50: "#ffe1e1",
      100: "##ffb1b1",
      200: "#ff7f7f",
      300: "#ff4c4c",
      400: "#ff1a1a",
      500: "#e60000",
      600: "#b40000",
      700: "#810000",
      800: "#500000",
      900: "#210000",
    },
  },
};

export default extendTheme(theme);
